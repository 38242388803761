import { format } from "date-fns";

const Tabla = ({ data, onChange, onClick, nextPage, prevPage, links }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan={12} className="border-0">
              <div className="d-flex justify-content-end w-100 my-2">
                <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                  <ul class="pagination bg-transparent">
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8"
                          onClick={prevPage}
                          disabled={data.current_page == 1 ? true : false}
                        >
                          Anterior
                        </button>
                      ) : (
                        ""
                      )}
                    </li>

                    {data
                      ? data.links.map((item, index) => {
                          if (
                            (item.label !== "&laquo; Previous" &&
                              item.label !== "Next &raquo;" &&
                              item.active == true) ||
                            item.label == 1 ||
                            item.label == data.current_page - 1 ||
                            item.label == data.current_page + 1 ||
                            item.label == data.current_page + 2 ||
                            item.label == data.per_page ||
                            item.label == data.last_page
                          ) { 
                            return (
                              <li
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true ? "fs-8" : "fs-8"
                                }   
                              > 
                                <button
                                  style={{ borderRadius: "5px" }}
                                  className={
                                    item.active == true
                                      ? "btn btn-warning me-1 fs-8"
                                      : "btn btn-outline-warning me-1 fs-8"
                                  }
                                  onClick={(event) => links(event, item.url)}
                                >
                                  {item.label}
                                </button>
                              </li>
                            );
                          }
                        })
                      : ""}
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8 "
                          onClick={nextPage}
                          disabled={
                            data.current_page == data.last_page ? true : false
                          }
                        >
                          Siguiente
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </th>
          </tr>
          <tr>
            <th className="border-0">Actualizado</th>
            <th className="border-0">Nombre</th>
            <th className="border-0">Codigo</th>
            <th className="border-0">Codigo de barra</th>
            <th className="border-0">Precio /Lista</th>
            <th className="border-0">Precio /Descuento</th>
            <th className="border-0">Rubro</th>
            <th className="border-0">Subrubro</th>
            <th className="border-0">Estado</th>
            <th className="border-0" colSpan={2}></th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((producto) => (
            <tr>
              <td className="border-0">{format(new Date(producto.updated_at), "dd/MM/Y HH:mm:ii").toString()}</td>
              <td className="border-0">{producto.name}</td>
              <td className="border-0">{producto.code}</td>
              <td className="border-0">{producto.bar_code}</td>
              <td className="border-0">{parseFloat(producto.price).toFixed(2)}</td>
              <td className="border-0">{parseFloat(producto.price_list).toFixed(2)}</td>
              <td className="border-0">
                {producto.rubros.length > 0
                  ? producto.rubros[0].name
                  : "Sin asignar"}
              </td>
              <td className="border-0">
                {producto.subrubros.length > 0
                  ? producto.subrubros[0].name
                  : "Sin asignar"}
              </td>
              <td className="border-0">{producto.status.name}</td>
              <td className="border-0">
                <label className="btn btn-outline-success">
                  <i class="bi bi-pencil"></i>
                  <button
                    className="d-none"
                    name="modal_art"
                    onClick={(event) => onClick(event, producto)}
                  ></button>
                </label>
              </td>
              <td className="border-0">
                <div class="dropdown">
                  <button
                    class="btn btn-outline-dark rounded-pill dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cambiar estado...
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <label className="btn w-100 d-flex justify-content-start m-0">
                        <i class="text-success me-1 bi bi-check-circle-fill"></i>
                        Activar
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, { data: producto, status: 1 })
                          }
                        ></button>
                      </label>
                    </li>
                    <li>
                      <label className="btn w-100 d-flex justify-content-start m-0">
                        <i class="text-danger me-1 bi bi-x-circle-fill"></i>
                        Desactivar
                        <button
                          className="d-none"
                          name="change_status"
                          onClick={(event) =>
                            onClick(event, { data: producto, status: 2 })
                          }
                        ></button>
                      </label>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={12} className="border-0">
              <div className="d-flex justify-content-end w-100 my-2">
                <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                  <ul class="pagination bg-transparent">
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8"
                          onClick={prevPage}
                          disabled={data.current_page == 1 ? true : false}
                        >
                          Anterior
                        </button>
                      ) : (
                        ""
                      )}
                    </li>

                    {data
                      ? data.links.map((item, index) => {
                          if (
                            (item.label !== "&laquo; Previous" &&
                              item.label !== "Next &raquo;" &&
                              item.active == true) ||
                            item.label == 1 ||
                            item.label == data.current_page - 1 ||
                            item.label == data.current_page + 1 ||
                            item.label == data.current_page + 2 ||
                            item.label == data.per_page ||
                            item.label == data.last_page
                          ) {
                            return (
                              <li
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true ? "fs-8" : "fs-8"
                                }
                              >
                                <button
                                  style={{ borderRadius: "5px" }}
                                  className={
                                    item.active == true
                                      ? "btn btn-warning me-1 fs-8"
                                      : "btn btn-outline-warning me-1 fs-8"
                                  }
                                  onClick={(event) => links(event, item.url)}
                                >
                                  {item.label}
                                </button>
                              </li>
                            );
                          }
                        })
                      : ""}
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8 "
                          onClick={nextPage}
                          disabled={
                            data.current_page == data.last_page ? true : false
                          }
                        >
                          Siguiente
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Tabla;
