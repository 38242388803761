import { useState } from "react";
import { apiRest } from "../../../../../hooks/useRest";
import CustomAlerts from "../../../../../alerts/CustomAlerts";

const ModalCondicion = ({
  setModal,
  select,
  setSelect,
  setCondiciones,
  metodosPago,
  rubros,
  fetch,
}) => {
  console.log(select);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id: select ? select.id : null,
    payment_method_id: select ? select.payment_method_id : null,
    rubro_id: select ? select.rubro_id : null,
    name: select ? select.name : "",
    coefficient: select ? select.coefficient : "",
    discount: select ? select.discount : 1,
    status: select ? select.status : "activo",
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setSelect(null);
          setModal(false);
        }
        break;
      case "guardar":
        {
          //recreate form
          let formData = new FormData();
          formData.append("name", form.name);
          formData.append("payment_method_id", form.payment_method_id);
          formData.append("rubro_id", form.rubro_id);
          formData.append("coefficient", form.coefficient);
          formData.append("discount", form.discount);
          formData.append("status", form.status);

          if (select) {
            //send api rest
            const response = await apiRest({
              url: "/Auth/condicion/" + select.id,
              type: "POST",
              form: formData,
              setLoading: setLoading,
            });

            if (response.status == 200 || response.status == 201) {
              let filter = {};
              fetch("/Auth/condicion", filter, setCondiciones);
              return setModal(false);
            }
          }

          if (
            form.name.trim() == "" ||
            form.payment_method_id.trim() == "" ||
            form.rubro_id.trim() == "" ||
            form.coefficient.trim() == ""
          ) {
            return CustomAlerts({
              size: "xl",
              type: "error",
              title: "Error",
              msg: "Debe completar todos los campos para cargar una nueva condición",
            });
          }

          //send api rest
          const response = await apiRest({
            url: "/Auth/condicion",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          if (response.status == 200 || response.status == 201) {
            let filter = {};
            fetch("/Auth/condicion", filter, setCondiciones);
            return setModal(false);
          }

          if (response.status == 400 || response.status == 401) {
            return CustomAlerts({
              size: "xl",
              type: "error",
              title: "Error",
              msg: "Ya existe una condición con el tipo de pago y rubro asignado",
            });
          }
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "status":
      case "discount":
      case "coefficient":
      case "payment_method_id":
      case "rubro_id":
      case "coefficient":
      case "name":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  return (
    <div
      className="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        className="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div className="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Añadir nueva condición</h1>
              <span className="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                className="border-0 bg-transparent "
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="body p-3">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill border-primary"
                  placeholder="Ingresa un nombre a tu condicion"
                  name="name"
                  defaultValue={form.name}
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="col-md-6 mb-3 ">
                {" "}
                <label for="exampleFormControlInput1" className="form-label">
                  Es un descuento
                </label>
                <select
                  className="form-select rounded-pill border-primary"
                  aria-label="Default select example"
                  name="discount"
                  onChange={(event) => onChange(event)}
                >
                  {[
                    { option: "Si", value: 1 },
                    { option: "No", value: 0 },
                  ].map((discount, index) => {
                    if (discount.value == select?.discount) {
                      return <option selected>{discount.option}</option>;
                    }

                    if (discount.value != select?.discount) {
                      return (
                        <option value={discount.value}>
                          {discount.option}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>

              <div className="col-md-12 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Coeficiente
                </label>
                <input
                  type="number"
                  className="form-control rounded-pill border-primary"
                  placeholder="0.00"
                  name="coefficient"
                  defaultValue={form.coefficient}
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="col-md-12 mb-3 ">
                {" "}
                <label for="exampleFormControlInput1" className="form-label">
                  Metodo de pago
                </label>
                <select
                  className="form-select rounded-pill border-primary"
                  aria-label="Default select example"
                  name="payment_method_id"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>
                    {metodosPago.filter(
                      (item) => item.id == select?.payment_method_id
                    ).length > 0
                      ? metodosPago.filter(
                          (item) => item.id == select?.payment_method_id
                        )[0].description
                      : ""}
                  </option>
                  {metodosPago.map((mp, index) => (
                    <option value={mp.id}>{mp.description}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3 ">
                {" "}
                <label for="exampleFormControlInput1" className="form-label">
                  Aplicado al rubro
                </label>
                <select
                  className="form-select rounded-pill border-primary"
                  aria-label="Default select example"
                  name="rubro_id"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>
                    {rubros.filter((item) => item.id == select?.rubro_id)
                      .length > 0
                      ? rubros.filter((item) => item.id == select?.rubro_id)[0]
                          .name
                      : ""}
                  </option>
                  {rubros.map((rubro, index) => (
                    <option value={rubro.id}>{rubro.name}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-12 mb-3 ">
                {" "}
                <label for="exampleFormControlInput1" className="form-label">
                  Estado
                </label>
                <select
                  className="form-select rounded-pill border-primary"
                  aria-label="Default select example"
                  name="status"
                  onChange={(event) => onChange(event)}
                >
                  {[
                    { option: "Activo", value: "activo" },
                    { option: "Inactivo", value: "inactivo" },
                  ].map((status) => {
                    if (status.value == select?.status) {
                      return <option selected>{status.option}</option>;
                    }

                    if (status.value != select?.status) {
                      return (
                        <option value={status.value}>{status.option}</option>
                      );
                    }
                  })}
                  <option value={"inactivo"}>Inactivo</option>
                </select>
              </div>
            </div>
          </div>
          <div className="footer bg-white p-4">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary rounded-pill me-2"
                name="guardar"
                onClick={(event) => onClick(event)}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCondicion;
