import { useEffect, useState } from "react";
import clientAxios from "../../../../config/ConfigAxios";
import axios from "axios";
import ModalCondicion from "./modals/ModalCondicion";
import ModalMetodoPago from "./modals/ModalMetodoPago";

const MetodosDePago = () => {
  const [modalCondicion, setModalCondicion] = useState(false);
  const [condicion, setCondicion] = useState(null);
  const [modalMetodoPago, setModalMetodoPago] = useState(false);
  const [metodoPago, setMetodoPago] = useState(null);
  const [rubros, setRubros] = useState({
    data: null,
  });
  const [metodosPago, setMetodosPago] = useState(null);
  const [condiciones, setCondiciones] = useState(null);
  const [producto, setProducto] = useState(null);
  const [simulador, setSimulador] = useState({
    method_payment: null,
    condition: null,
    product: null,
  });

  let cancelTokenSource;

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "tipo-pago":
        {
          let method_payment = metodosPago.filter(
            (i) => i.id == e.target.value
          );

          let condition = null;


          if (method_payment.length > 0) {
            method_payment[0].conditions.map((condicion, index) => {
              if (producto?.data.rubros_id == condicion.rubro_id) {
                condition = condicion;
              }
            });


            setSimulador({
              ...simulador,
              method_payment: method_payment[0],
              condition: condition,
            });
          }
        }
        break;
      case "tipo-condicion":
        {
          let condition = condiciones.filter((i) => i.id == e.target.value);
          if (condition.length > 0) {
            setSimulador({
              ...simulador,
              condition: condition[0],
            });
          }
        }
        break;
      case "producto":
        {
          if (e.target.value.trim() == "") {
            return "";
          }

          let params = {
            code: e.target.value,
          };

          if (cancelTokenSource) {
            cancelTokenSource.cancel(
              "Petición cancelada debido a una nueva solicitud."
            );
          }

          cancelTokenSource = axios.CancelToken.source();

          const response = await clientAxios
            .get("/Productos/Mostrar", {
              params,
              cancelToken: cancelTokenSource.token,
            })
            .then((response) => {
              return response;
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                return error;
              } else {
                return error;
              }
            });

          if (response.status == 200 || response.status == 201) {
            if (response.data.data) {
              simulador.method_payment.conditions.map((condicion, index) => {
                if (response.data.data.rubros_id == condicion.rubro_id) {
                  setSimulador({
                    ...simulador,
                    condition: condicion,
                  });
                }
              });
            }

            setProducto(response.data);
          } else {
            setProducto({
              data: null,
              msg: "Producto no encontrado",
              status: 404,
            });
          }
        }
        break;
      default:
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "":
        {
        }
        break;

      default:
        break;
    }
  };

  const fetch = async (url, params, setData) => {
    const response = await clientAxios.get(url, { params });

    if (response.status == 200 || response.status == 201) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!metodosPago) {
      let filter = {};
      fetch("/Auth/metodosPago", filter, setMetodosPago);
    }
  }, []);

  useEffect(() => {
    if (!condiciones) {
      let filter = {};
      fetch("/Auth/condicion", filter, setCondiciones);
    }
  }, []);

  useEffect(() => {
    if (!rubros.data) {
      let filter = {};
      fetch("/Auth/Rubros", filter, setRubros);
    }
  }, []);

  //console.log(producto);
  //console.log(simulador);
  //console.log(rubros);

  const total = (producto, simulador) => {
    let _descuento = 0.0;
    let descuento = 0.0;
    let total = producto?.data ? producto?.data.price : 0.0;
    let price = producto?.data ? producto?.data.price : 0.0;
    let methodPayment = simulador.method_payment?.coefficient
      ? Math.abs(simulador.method_payment.coefficient)
      : 0.0;
    let condition = simulador?.condition?.coefficient
      ? simulador.condition.coefficient
      : 0.0;

    if (methodPayment > 0.99) {
      _descuento = (price / 100) * methodPayment;
    }

    if (simulador.condition?.rubro_id == producto?.data?.rubros_id) {
      descuento = price - price * condition;
    }

    total = total - descuento - _descuento;

    return {
      descuento: parseFloat(descuento).toFixed(2),
      total: parseFloat(total).toFixed(2),
    };
  };
  return (
    <div className="container-md">
      <div className="py-5">
        <h1>Metodos de pago</h1>
      </div>

      <div className="row">
        <div className="col-md-2 d-flex d-none">
          <label className="btn btn-primary rounded-pill text-nowrap">
            <i class="bi bi-plus-lg me-1"></i>Agregar metodo de pago
            <input
              name="catalogo"
              onChange={(event) => onChange(event)}
              type="file"
              className="d-none"
            />
          </label>
        </div>
        <div className="col-md-12">
          <div className="my-5">
            {metodosPago ? (
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">DESCRIPCION</th>
                      <th scope="col">DESCUENTO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metodosPago.map((metodoPago) => (
                      <tr>
                        <th>{metodoPago.id}</th>
                        <td>{metodoPago.name}</td>
                        <td>{metodoPago.description}</td>
                        <td>{metodoPago.coefficient}</td>
                        <td>
                          <label className="btn btn-outline-success">
                            <i class="bi bi-pencil"></i>
                            <button
                              className="d-none"
                              name="editar"
                              onClick={() => {
                                setMetodoPago(metodoPago);
                                setModalMetodoPago(true);
                              }}
                            ></button>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2">
          <label className="btn btn-primary rounded-pill text-nowrap">
            <i class="bi bi-plus-lg me-1"></i>Agregar condicion de precio
            <button
              className="d-none"
              name="modal_art"
              onClick={(e) => {
                setModalCondicion(true);
              }}
            ></button>
          </label>
        </div>
        <div className="col-md-12">
          <div className="my-5">
            {condiciones ? (
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">NOMBRE</th>
                      <th scope="col">COEFICIENTE</th>
                      <th scope="col">DESCUENTO</th>
                      <th scope="col">METODO DE PAGO</th>
                      <th scope="col">RUBRO</th>
                      <th scope="col">ESTADO</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {condiciones.map((condicion) => (
                      <tr>
                        <th>{condicion.id}</th>
                        <td>{condicion.name}</td>
                        <td>{condicion.coefficient}</td>
                        <td>{condicion.discount}</td>
                        <td>{condicion.payment_method?.name}</td>
                        <td>{condicion.rubros?.name}</td>

                        <td>
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-dark rounded-pill dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {condicion.status}
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <label className="btn w-100 d-flex justify-content-start m-0">
                                  <i class="text-success me-1 bi bi-check-circle-fill"></i>
                                  Activar
                                  <button
                                    className="d-none"
                                    name="change_status"
                                    onClick={(event) =>
                                      onClick(event, {
                                        data: condicion,
                                        status: "activo",
                                      })
                                    }
                                  ></button>
                                </label>
                              </li>
                              <li>
                                <label className="btn w-100 d-flex justify-content-start m-0">
                                  <i class="text-danger me-1 bi bi-x-circle-fill"></i>
                                  Desactivar
                                  <button
                                    className="d-none"
                                    name="change_status"
                                    onClick={(event) =>
                                      onClick(event, {
                                        data: condicion,
                                        status: "inactivo",
                                      })
                                    }
                                  ></button>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td>
                          <label className="btn btn-outline-success">
                            <i class="bi bi-pencil"></i>
                            <button
                              className="d-none"
                              name="editar"
                              onClick={() => {
                                setCondicion(condicion);
                                setModalCondicion(true);
                              }}
                            ></button>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2">
          <label className="text-dark fs-4 fw-bold rounded-pill text-nowrap">
            Simulador de precio
          </label>
        </div>

        <div className="col-md-12">
          <div class="mb-3">
            <label for="basic-url" class="form-label">
              Selecciona las condiciones de pago
            </label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon3">
                Tipo de pago
              </span>
              <select
                class="form-select"
                name="tipo-pago"
                onChange={(e) => onChange(e)}
              >
                <option selected>Selecciona un tipo de pago...</option>
                {metodosPago
                  ? metodosPago.map((mp, index) => (
                      <option value={mp.id}>{mp.description}</option>
                    ))
                  : null}
              </select>
            </div>
          </div>

          <div class="mb-3">
            <label for="basic-url" class="form-label">
              Condiciones de pago activas para este producto
            </label>
            <div class="input-group">
              <span class="input-group-text" id="basic-addon3">
                Condicion de pago
              </span>
              <input
                type="text"
                class={
                  producto?.msg == "Producto no encontrado"
                    ? "form-control  border border-1 border-danger"
                    : "form-control "
                }
                value={simulador.conditions?.name}
                name="producto"
                onChange={(e) => onChange(e)}
                disabled
              />
            </div>
          </div>

          {simulador.method_payment ? (
            <div className="my-5">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                  Selecciona un producto
                </span>
                <input
                  type="text"
                  class={
                    producto?.msg == "Producto no encontrado"
                      ? "form-control  border border-1 border-danger"
                      : "form-control "
                  }
                  placeholder="67787..."
                  name="producto"
                  onChange={(e) => onChange(e)}
                />
              </div>

              {producto?.data ? (
                <ul class="list-group list-group-horizontal">
                  <li class="list-group-item">{producto.data.name}</li>
                  <li class="list-group-item">
                    {producto.data.rubros[0].name}
                  </li>
                  <li class="list-group-item">
                    {producto.data.subrubros[0].name}
                  </li>
                </ul>
              ) : (
                <span className="text-danger">Producto no encontrado</span>
              )}

              <div class="form-text" id="basic-addon4">
                Precio de lista
              </div>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="0.00"
                  value={producto?.data?.price}
                />
                <span class="input-group-text" id="basic-addon2">
                  <i class="bi bi-currency-dollar"></i>
                </span>
              </div>

              <div class="form-text" id="basic-addon4">
                Descuento
              </div>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="0.00"
                  value={total(producto, simulador).descuento}
                />
                <span class="input-group-text" id="basic-addon2">
                  <i class="bi bi-currency-dollar"></i>
                </span>
              </div>

              <div class="form-text" id="basic-addon4">
                Total
              </div>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="0.00"
                  value={total(producto, simulador).total}
                />
                <span class="input-group-text" id="basic-addon2">
                  <i class="bi bi-currency-dollar"></i>
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {modalCondicion ? (
        <ModalCondicion
          setModal={setModalCondicion}
          select={condicion}
          setSelect={setCondicion}
          fetch={fetch}
          setCondiciones={setCondiciones}
          rubros={rubros.data ? rubros.data : []}
          metodosPago={metodosPago}
        />
      ) : (
        ""
      )}

      {modalMetodoPago ? (
        <ModalMetodoPago
          setModal={setModalMetodoPago}
          select={metodoPago}
          setSelect={setMetodoPago}
          fetch={fetch}
          setMetodosPago={setMetodosPago}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default MetodosDePago;
